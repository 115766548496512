/** --------  Fonts  -------- **/

@font-face {
    font-family: 'webfontregular';
    src: url('fonts/buenosaires-black-webfont.woff2') format('woff2'),
         url('fonts/buenosaires-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/** --------  Body  -------- **/

body {
  font-family: 'Raleway', sans-serif;
  color: #37383A;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
}

a, a:hover, a:visited {
  text-decoration: none;
  outline: 0;
}

h1, h2, h3, h4, h5, h6, #title {
  margin-top: 0;
  font-weight: 800;
  line-height: 1.5;
}


/** --------  Loader  -------- **/

time, #category, .social a, .pagination a {
  color: #A0A0A0;
  font-family: 'webfontregular';
  font-size: 0.8rem;
  text-transform: uppercase;
}


/** --------  Animation  -------- **/

.social a, .social svg path, #banner a, .articleWebsite, .pagination a, .articleNav a svg path, footer a {
  transition: 400ms ease-in-out;
}


/** --------  Main  -------- **/
.uk-section {
  position: relative;
}

main.uk-section, article.uk-section {
  padding: 0;
}

#category {
  margin-bottom: 0.4rem;
}

#title {
  margin-top: 0;
}

header.black, .banner--home {
  background-color: #333333;
}

header.white #Mark_Bucknall * {
  fill: #333;
}

.social {
  height: 100%;
  align-content: center;
}

.social a {
  font-size: 0.9rem;
}

.social a:hover {
  color: #FFF;
}

.social svg {
  width: 16px;
}

.social svg path {
  fill: #C1C1C1;
}

header.black .social svg:hover path {
  fill: #FFF;
}

header.white .social svg:hover path {
  fill: #333;
}

header.white a:hover {
  color: #333;
}

#banner * {
  color: #FFF;
}

#banner h1 {
  max-width: 1200px;
  margin-bottom: 4rem;
  letter-spacing: 1px;
}

#banner h1 strong {
  font-weight: 800;
  color: #A0A0A0;
}

#banner a {
  color: #A0A0A0;
}

#banner a:hover {
  color: #FFF;
}

.banner__content {
  margin-bottom: 12rem !important;
}

.banner--image {
  height: 640px;
  background-size: contain;
}

#articles {
  margin-top: -14rem;
}

#editor {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
}

.uk-navbar-container {
  background: #fff !important;
}

.pagination {
  margin: 0;
  padding: 0;
}

.pagination, .pagination li {
  display: inline-block;
}

.pagination li {
  padding: 0 0.2rem;
}

.pagination a:hover, .pagination .currentPage a {
  color: #222;
}

/*.currentPage {
  display: none !important;
}*/

.forwardPage, .reverse {
  font-size: 2em;
}

img:hover {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.uk-card-body {
  padding-left: 0;
  padding-right: 0;
}

.uk-card-media-top {
  overflow: hidden;
}

.articleContent {
  margin-bottom: 8rem;
}

.articleNav a:hover, .pagination .currentPage a {
  border-color: #222;
}

.articleNav a:hover svg path {
  fill: #222;
}

.articleLink .uk-card-media-top {
  position: relative;
}

.articleLink img {
  width: 100%;
  transition: transform 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.articleLink:hover img {
  transform: scale(1.1) rotate(3deg);
}

.articleLink:hover .articleWebsite {
  opacity: 1;
}

.articleImage {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  max-width: 800px;
}

.articleWebsite {
  margin-left: 2rem;
}

.articleWebsite, .pagination a {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #A0A0A0;
}

.articleWebsite svg, .pagination span {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  width: 20px;
  text-align: center;
}

.articleWebsite svg path {
  fill: #A0A0A0;
}

.articles .articleWebsite {
  opacity: 0;
  position: absolute;
  z-index: 1;
  bottom: 2rem;
  right: 2rem;
  border-color: #FFF;
  background-color:rgba(85,85,85,0.55);
}

.articles .articleWebsite:hover {
  background-color:rgba(85,85,85,1);
}

.articles .articleWebsite svg path {
  fill: #FFF;
}

.articleGallery {
  margin-bottom: 4rem;
}

.articleGallery img {
  max-width: 80%;
  margin: 4rem 10%;
  -webkit-box-shadow: 0px 0px 99px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 99px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 99px 0px rgba(0,0,0,0.25);
}

footer {
  text-align: center;
}

footer *, footer a:hover {
  color: #79787D;
}

footer a {
  color: #222;
}

.signup p {
  margin-bottom: 4rem;
  white-space: break-spaces;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
}

.copyright strong {
  margin: 0.2rem 2rem;
  font-weight: normal;
}


/** --------  Media Queries  -------- **/

@media only screen and (max-width: 1920px) {

  .banner--image {
    background-size: cover;
  }

}

@media only screen and (max-width: 960px) {

  .banner--image {
    height: 480px;
  }

}

@media only screen and (max-width: 768px) {

  .banner--image, .loader--banner {
    height: 320px;
  }

  .copyright strong {
    display: block;
    margin: 0.2rem 0;
  }

}

@media only screen and (max-width: 640px) {

  .banner--home {
    padding-top: 0;
  }

  #banner h1 {
    font-size: 1.6em;
  }

  .articleContent {
    margin-bottom: -10rem;
  }

  .articleImage {
    position: static;
    width: 100%;
  }

  .articleGallery img {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .articleWebsite {
    margin: 0;
    display: flex;
  }

}



/** --------  Tooltip  -------- **/
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  transition: 400ms 0s cubic-bezier(0.39, 0.575, 0.565, 1);
}

/* Position tooltip above the element */
[data-tooltip]:before {
    position: absolute;
    bottom: 120%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -50px;
    padding: 12px;
    width: 80px;
    border-radius: 3px;
    background-color: #222;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 120%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #222;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  transition: 400ms 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}


/** --------  Loader  -------- **/
.loader {
  margin: 2em;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.loader::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 320px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.loader--banner {
  height: 640px;
}

.loader--content {
  max-width: 1200px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}


